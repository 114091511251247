<template>
  <v-row no-gutters>
    <v-col cols="3" />
    <v-col cols="6">
      <h2 class="red--text text-center font-weight-light">Forgot Password?</h2>
      <h1 class="red--text font-weight-thin text-center">Enter New Password</h1>
      <v-divider />
      <div class="text-center grey--text">
        <v-text-field
          label="Password"
          :rules="[rules.min_password]"
          v-model="password"
          hint="The password that will be used to log in to the website."
          outlined
          clearable
          dense
          type="password"
          :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
        />
        <v-text-field
          label="Repeat Password"
          v-model="confirm_password"
          :rules="[rules.min_password]"
          hint="Type again to verify it's correct!"
          outlined
          clearable
          dense
          type="password"
        />
        <v-btn color="success" @click="submit_request" :disabled="can_join"
          >Change Password</v-btn
        >
      </div>
    </v-col>
    <v-col cols="3" />
  </v-row>
</template>
<script>
import store from "@/store";

import { FORGOT_PASSWORD, LOGIN } from "@/store/actions.type";
import { BEGIN_LOADING } from "@/store/mutations.type";

export default {
  props: {
    token: {
      type: String,
      required: true,
    },
  },
  computed: {
    can_join: function () {
      return !(
        this.password &&
        this.confirm_password &&
        this.password == this.confirm_password
      );
    },
  },
  methods: {
    submit_request: function () {
      store.commit(BEGIN_LOADING);
      store
        .dispatch(FORGOT_PASSWORD, {
          token: this.token,
          password: this.password,
        })
        .then(() => {
          store
            .dispatch(LOGIN, {
              user: this.email,
              password: this.password,
            })
            .then(() => {
              this.$router.push({ name: "Home" });
            });
        });
    },
  },
  data: () => ({
    show_password: false,
    password: "",
    confirm_password: "",
    rules: {
      max_username: (v) => v.length <= 25 || "Max 25 characters",
      min_password: (v) => v.length >= 5 || "Min 5 characters",
    },
  }),
};
</script>
